<template>
    <transition name="fade">
        <div class="vue-modal" v-show="open">
            <transition name="drop-in">
                <div class="vue-modal-inner" v-show="open">
                    <div class="vue-modal-content">
                        <!-- Slot para contenido dinámico -->
                        <slot />
                        <div class="button-group">
                            <!-- Botón Sí -->
                            <button class="btn btn-primary" type="button" @click="eliminarVacante">Sí</button>
                            <!-- Botón No -->
                            <button class="btn btn-secondary" type="button" @click="close">No</button>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </transition>

</template>

<script>
import { CompanyService } from "@/services";
import { onMounted, onUnmounted } from 'vue';

export default {
    props: {
        open: {
            type: Boolean,
            required: true
        },
        idVacante: {
            type: String,
            required: true
        }
    },
    setup(_, { emit }) {

        const close = () => {
            emit('close')
        }

        const handleKeyup = (event) => {
            if (event.keyCode === 13) {
                close()
            }
        }

        onMounted(() => document.addEventListener("keyup", handleKeyup));
        onUnmounted(() => document.removeEventListener("keyup", handleKeyup));

        return { close };
    },
    methods: {
        async eliminarVacante() {
            try {
                await CompanyService.deleteVacanteById(this.idVacante);
                this.$swal({
                    position: "top-center",
                    icon: "success",
                    title: "Vacante eliminada con éxito",
                    showConfirmButton: false,
                    timer: 3000,
                });
                this.$router.push('/dashboard')

            } catch (e) {
                console.log(e);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../styles/deleteconfirmation.scss";
</style>