<template>
    <div class="" style="margin-top: 7%;">

        <div class="container">
            <div class="J-flex between mb-3">
                <div class="col-4 J-flex start">
                    <router-link to="/dashboard" class="btn btn-outline-primary btn-sm">
                        <box-icon name='left-arrow-alt' color='#1e9dea'></box-icon>
                    </router-link>
                </div>
            </div>


            <div class="card">
                <div class="card-header d-flex justify-content-between align-items-center">
                    <!-- Título -->
                    <strong>
                        {{ details ? titleDetails : state.id == "" ? title : titleEdit }} {{ id_from_url }}
                    </strong>

                    <!-- Vigencia / Estado de la vacante -->
                    <div class="card-text" style="color: red">
                        {{ state.dicVacante.vacanteSuspended ? "Vacante Pausada" :
                            moment(state.dicVacante.createdAt).isAfter(this.vigencia) ? "Vacante Vigente" :
                                "Vacante Caducada" }}
                    </div>
                </div>


                <div class="card-body">
                    <input type="hidden" v-model="state.id" />
                    <form @submit.prevent="savedData()">

                        <div class="d-flex justify-content-between align-items-center">

                            <div></div>

                            <button v-if="!state.dicVacante.vacanteSuspended" type="button" v-tooltip="'Pausar vacante'"
                                @click="suspenderVacante"
                                :disabled="moment(state.dicVacante.createdAt).isBefore(this.vigencia)"
                                class="btn btn-primary me-2 action-btn">
                                <box-icon name='pause-circle' color="white" style="cursor: pointer;"></box-icon>
                            </button>
                            <button v-if="state.dicVacante.vacanteSuspended" type="button"
                                v-tooltip="'Reanudar vacante'" @click="suspenderVacante"
                                :disabled="moment(state.dicVacante.createdAt).isBefore(this.vigencia)"
                                class="btn btn-primary action-btn">
                                <box-icon name='play-circle' color="white" style="cursor: pointer;"></box-icon>
                            </button>

                        </div>



                        <div class="row mb-2">
                            <div class="col-md-3">
                                <label for="responsable" class="form-label"><strong>Responsable</strong></label>
                                <input type="text" id="responsable" class="form-control" v-model="state.responsable"
                                    :readonly="details" required>
                            </div>
                            <div class="col-md-3">
                                <label for="supervisor" class="form-label"><strong>Supervisor</strong> </label>
                                <input type="text" id="supervisor" class="form-control" v-model="state.supervisor"
                                    :readonly="details" required>
                            </div>
                            <div class="col-md-3">
                                <label for="empresa" class="form-label"><strong>Unidad/División</strong></label>
                                <input type="text" id="empresa" class="form-control" v-model="state.company" readonly
                                    required style="background-color:#D6D4D3 !important;">
                            </div>
                            <div class="col-md-3">
                                <label for="area" class="form-label"><strong>Área</strong></label>
                                <input type="text" id="area" class="form-control" v-model="state.area"
                                    :readonly="details" required>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-md-3"><label for="vacante" class="form-label"><strong>Vacante</strong>
                                </label>
                                <input type="text" id="vacante" class="form-control" v-model="state.vacante" readonly
                                    style="background-color:#D6D4D3 !important;" required>
                            </div>
                            <div class="col-md-3">
                                <label for="manager" class="form-label"><strong>Hiring Manager</strong></label>
                                <input type="text" id="manager" class="form-control" v-model="state.hiringManager"
                                    :readonly="details">
                            </div>

                            <div class="col-md-3">
                                <label class="form-label" for="statusSelect"><strong>Status</strong></label>
                                <select class="form-select" id="statusSelect" v-model="state._id_estatus"
                                    :disabled="details" required>
                                    <option value="" selected disabled>Seleccione...</option>
                                    <option v-for="(item, i) in estatus" :key="i + 1" :value="item._id">
                                        {{ item.name }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-3">
                                <label class="form-label" for="nivelSelect"><strong>Nivel</strong></label>
                                <select class="form-select" id="nivelSelect" v-model="state._id_nivel"
                                    :disabled="details" required>
                                    <option value="" selected disabled>Seleccione...</option>

                                    <option v-for="(item, i) in niveles" :key="i + 1" :value="item._id">
                                        {{ item.name }}
                                    </option>
                                </select>
                            </div>

                        </div>
                        <div class="row mb-2">
                            <div class="col-md-3">
                                <label for="start" class="form-label"><strong>Fecha de Inicio</strong></label>
                                <input type="date" name="start" id="strart" class="form-control" v-model="state.start"
                                    :disabled="details" required>
                            </div>

                            <div class="col-md-3">
                                <label for="terna" class="form-label"><strong>Fecha de Terna</strong></label>
                                <span v-if="state.terna" class="indicador">
                                    {{
                            tiempoTranscurrido(state.start, state.terna)
                        }}
                                </span>
                                <input type="date" name="terna" id="terna" class="form-control" v-model="state.terna"
                                    :disabled="details">
                            </div>

                            <div class="col-md-3">
                                <label for="closingDate" class="form-label"><strong>Fecha fin</strong></label>
                                <span v-if="state.closingDate" class="indicador">
                                    {{
                            tiempoTranscurrido(state.start, state.closingDate)
                        }}
                                </span>
                                <input type="date" id="closingDate" class="form-control" v-model="state.closingDate"
                                    :disabled="details" :required="state.status == 'Terminado'">
                            </div>
                            <div class="col-md-3">
                                <label for="admisionDate" class="form-label"><strong>Fecha de Admisión</strong></label>
                                <span v-if="state.admisionDate" class="indicador">
                                    {{
                            tiempoTranscurrido(state.start, state.admisionDate)
                        }}
                                </span>
                                <input type="date" id="admisionDate" class="form-control" v-model="state.admisionDate"
                                    :disabled="details">
                            </div>

                        </div>

                        <div class="row mb-2">
                            <div class="col-md-3">
                                <label for="control" class="form-label"><strong>Número de Vacante</strong></label>
                                <input type="text" id="control" class="form-control" v-model="state.control"
                                    :readonly="details" required>
                            </div>
                            <div class="col-md-3">
                                <label for="meta" class="form-label"><strong>Días para Cubrir la
                                        Vacante</strong></label>
                                <input type="number" id="meta" class="form-control" v-model="state.meta"
                                    :readonly="details" required>
                            </div>
                            <div class="col-md-3">
                                <label for="minimumLimit" class="form-label"><strong>Días para status amarillo
                                    </strong></label>
                                <input type="number" id="minimumLimit" class="form-control" v-model="state.minimumLimit"
                                    :readonly="details" required>
                            </div>
                            <div class="col-md-3">
                                <label class="form-label" for="federalEntities"><strong>Estado</strong></label>
                                <select class="form-select" id="federalEntities" v-model="state.federalEntities"
                                    disabled style="background-color:#D6D4D3 !important;" required>
                                    {{ state.federalEntities }}
                                    <option value="" selected disabled>Seleccione...</option>
                                    <option v-for="(item, i) in estados" :key="i + 1" :value="item.estado">
                                        {{ item.estado }}
                                    </option>
                                </select>
                            </div>

                        </div>

                        <div class="row mb-2">

                            <div class="col-md-3">
                                <label for="totalPositions" class="form-label"><strong>Total de
                                        Posiciones</strong></label>
                                <input type="number" id="totalPositions" class="form-control"
                                    v-model="state.totalPositions" :readonly="details">
                            </div>
                            <div class="col-md-3">
                                <label for="progressCandidates" class="form-label">
                                    <strong>Candidatos en Progreso</strong>
                                </label>
                                <input type="number" id="progressCandidates" class="form-control"
                                    v-model="state.progressCandidates" :readonly="details">
                            </div>
                            <div class="col-md-3" style="display: flex; justify-content: center; align-items: center;">
                                <label for="link" class="form-label"><strong>Link de la Vacante</strong>
                                    <a v-show="state._id_vacante" :href="state._id_vacante ? urlBase : '#'"
                                        class="btn btn-icon btn-warning btn-hover-primary btn-sm mx-3" target="_blank">
                                        <box-icon name='link-external' size='sm'></box-icon>
                                    </a>
                                </label>
                                <input v-show="showLink" type="text" id="link" class="form-control" v-model="state.link"
                                    :readonly="details">
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-md-12">
                                <label class="form-label" for="comentarios"><strong>Comentarios</strong></label>
                                <textarea type="text" id="comentarios" class="form-control" v-model="state.comments"
                                    cols="3" :readonly="details"> </textarea>
                            </div>
                        </div>

                        <br />

                        <div class="J-flex between mt-5">
                            <div class="col-4 J-flex start">

                                <button class="btn btn-danger" type="button" @click="isOpen = true" v-if="state.id">
                                    Eliminar
                                </button>
                            </div>

                            <div class="col-4 J-flex end">
                                <button type="submit" class="btn btn-success">Guardar</button>
                            </div>
                        </div>


                        <Modal :open="isOpen" :idVacante="state.id" @close="isOpen = !isOpen">
                            <p>
                                ¿Está seguro que desea eliminar la vacante?
                            </p>
                        </Modal>
                        <ModalClose :open="isOpenClose" :idVacante="state._id_vacante"
                            @close="isOpenClose = !isOpenClose">
                            <p>
                                ¿Ahora qué has cerrado tu proceso, te gustaría pausar la publicación de la vacante?
                            </p>
                        </ModalClose>

                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import { CompanyService } from "@/services";
import Modal from "./notification/ModelConfirmation.vue";
import ModalClose from "./notification/ModelConfirmationCloseVacante.vue";

import moment from "moment";
moment.locale("es");

export default {
    name: "FormularioVacante",
    components: { Modal, ModalClose },
    setup() {
        const isOpen = ref(false);
        const isOpenClose = ref(false);
        return { isOpen, isOpenClose }
    },
    mounted() {
        try {
            this.details = this.$route.params.details == "1" ? true : false;
            const id_from_url = this.$route.params.id;
            if (id_from_url) this.getVacante(id_from_url);

        } catch (e) {
            console.error(e);
        }
    },
    created() {
        this.getEstados();
        this.getNiveles();
        this.getEstatus();
    },
    data() {
        return {
            title: "Nueva Vacante",
            titleDetails: "Detalles de la Vacante",
            titleEdit: "Editar Vacante",
            status: ['En progreso', 'Crítico', 'Holding', 'Cancelado', 'Terminado', 'Cubierto Interno', 'Feedback'],
            //niveles: ['I. Becario - Practicante', 'II. Staff', 'III. Mando Medio', 'IV. Gerente', 'V. Director', 'VI. Ejecutivo'],
            niveles: [],
            estatus: [],
            estados: [],
            user: JSON.parse(localStorage.getItem("user")),
            showLink: false,
            vigencia: new Date(moment(new Date()).subtract('2', 'months').format('YYYY-MM-DD hh:mm:ss')),
            state: {
                id: "",
                control: "",
                responsable: "",
                supervisor: "",
                company: "",
                area: "",
                vacante: "",
                hiringManager: "",
                terna: "",
                start: new Date().toISOString().slice(0, 10),
                status: "",
                closingDate: "",
                admisionDate: "",
                comments: "",
                meta: "",
                minimumLimit: "",
                maximumLimit: "",
                _id_user: "",
                details: "",
                daysToClose: "",
                viableCandidates: "",
                progressCandidates: "",
                federalEntities: "",
                totalPositions: "",
                link: "",
                _id_nivel: "",
                _id_estatus: "",
                _id_vacante: "",
                nivel: "",
                dicVacante: ""
            },
            urlBase: "",
            moment: moment,
            userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
    },
    methods: {
        async savedData() {
            try {
                if (this.state.id) {

                    this.state.start = this.state.start + 'T00:00';
                    this.state.closingDate = this.state.closingDate ? this.state.closingDate + 'T00:00' : '';
                    this.state.admisionDate = this.state.admisionDate ? this.state.admisionDate + 'T00:00' : '';
                    this.state.terna = this.state.terna ? this.state.terna + 'T00:00' : '';
                    this.state.daysToClose = this.state.status == this.status[4] && this.state.closingDate ? this.tiempoTranscurrido(this.state.start, this.state.closingDate) : 0;

                    await CompanyService.updateVacanteDasboard(this.state);

                } else {
                    this.state._id_user = this.user._id;
                    await CompanyService.createVacanteDasboard(this.state);

                }
                this.$swal({
                    position: "top-center",
                    icon: "success",
                    title: "Datos guardados correctamente.",
                    showConfirmButton: false,
                    timer: 3000,
                });

                this.$router.push('/dashboard')

            } catch ($e) {
                this.$swal({
                    position: "top-center",
                    icon: "error",
                    title: "Falló la validación del formulario",
                    showConfirmButton: false,
                    timer: 2000,
                });
            }
        },
        async getVacante(id) {
            try {
                const response = await CompanyService.getDasboardCompanyById(id);
                const { data } = response.data;
                console.log('vacante =>', data);
                this.state = {
                    id: data._id,
                    control: data.control,
                    responsable: data.responsable,
                    supervisor: data.supervisor,
                    company: data.company,
                    area: data.area,
                    vacante: data.vacante,
                    hiringManager: data.hiringManager,
                    terna: data.terna ? moment.utc(data.terna).format('YYYY-MM-DD') : "",
                    start: moment.utc(data.start).format('YYYY-MM-DD'),
                    status: data.status,
                    closingDate: data.closingDate ? moment.utc(data.closingDate).format('YYYY-MM-DD') : "",
                    admisionDate: data.admisionDate ? moment.utc(data.admisionDate).format('YYYY-MM-DD') : "",
                    nivel: data.nivel,
                    comments: data.comments,
                    meta: data.meta,
                    minimumLimit: data.minimumLimit,
                    maximumLimit: data.maximumLimit,
                    viableCandidates: data.viableCandidates,
                    progressCandidates: data.progressCandidates,
                    federalEntities: data.federalEntities,
                    totalPositions: data.totalPositions,
                    link: data.link,
                    _id_user: data._id_user,
                    _id_vacante: data._id_vacante,
                    _id_nivel: data._id_nivel,
                    _id_estatus: data._id_estatus,
                    dicVacante: data.dicVacante

                };
                this.urlBase = window.location.origin + '/vacantes/vista/' + data._id_vacante;
            } catch (e) {
                console.log(e);
            }

        },

        tiempoTranscurrido(start, end) {

            var from = moment.utc(start);
            var to = moment.utc(end);
            var days = 0;

            while (!from.isAfter(to)) {
                // Si no es sabado ni domingo
                if (from.isoWeekday() !== 6 && from.isoWeekday() !== 7) {
                    days++;
                }
                from.add(1, 'days');
            }
            return days;
        },

        async getEstados() {
            try {
                const response = await CompanyService.getFederalEntities();
                const { data } = response.data;
                this.estados = data;

            } catch (e) {
                console.log(e);
            }

        },

        async getNiveles() {
            try {
                const response = await CompanyService.getNiveles();
                const { data } = response.data;
                this.niveles = data;

            } catch (e) {
                console.log(e);
            }
        },
        async getEstatus() {
            try {
                const response = await CompanyService.getEstatus();
                const { data } = response.data;
                this.estatus = data;

            } catch (e) {
                console.log(e);
            }
        },
        closeVacante() {
            this.isOpenClose = true;
        },
        async suspenderVacante() {
            try {
                await CompanyService.closeVacanteById(this.state.dicVacante._id).then(() => {
                    const { vacanteSuspended } = this.state.dicVacante;
                    const titulo = `Vacante ${vacanteSuspended ? "reiniciada" : "pausada"} con éxito`;

                    this.$swal({
                        position: "top-center",
                        icon: "success",
                        title: titulo,
                        showConfirmButton: false,
                        timer: 3000,
                    });
                    this.state.dicVacante.vacanteSuspended = !this.state.dicVacante.vacanteSuspended;

                });

            } catch (e) {
                console.log(e);
            }
        }

    }
}

</script>

<style lang="scss" scoped>
@import "../styles/formulariodashboard.scss";
</style>